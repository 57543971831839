import React from 'react';
import Layout from "../components/layout";
import {Helmet} from "react-helmet";

import "./news/news.scss"
const index = () => {
    return (<Layout pageId={"/recruit"}>
            <Helmet>
                <title>果心科技 | 人才服务</title>
                <meta name="keywords" content="果心科技 - 人才服务"/>
                <meta name="description" content="产品介绍 | 行业动态 | 公司动态 | 人才服务"/>
            </Helmet>
            <h1 className="text-center my-5">
                人才服务
            </h1>
            <div id="news-list-body" className="container my-5">
                <div className="row justify-content-start">
                    <div className="col-12 col-lg-4 p-2 ">
                       <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                    项目经理
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    20-25K
                                </div>
                            </div>
                           <div className="pb-3">
                               <span className="badge badge-secondary mr-1">5-10年</span>
                               <span className="badge badge-secondary mr-1">本科</span>
                               <span className="badge badge-secondary mr-1">非技术项目管理</span>
                               <span className="badge badge-secondary mr-1">团队管理经验</span>
                           </div>
                           <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                               <div style={{color: '#666', fontSize: 13}}>
                                  某大型新能源公司
                               </div>
                               <div style={{color: '#666', fontSize: 13}}>
                                  北京
                               </div>
                           </div>
                       </div>
                    </div>

                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                   特医渠道经理
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    30-45K
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">经验不限</span>
                                <span className="badge badge-secondary mr-1">本科</span>
                                <span className="badge badge-secondary mr-1">市场开发及维护</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                    某大型知名生活服务公司
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                   微信销售
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    4-9K
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">1-3年</span>
                                <span className="badge badge-secondary mr-1">学历不限</span>
                                <span className="badge badge-secondary mr-1">用户深度研究</span>
                                <span className="badge badge-secondary mr-1">业务数据分析</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京良乡亿丰堂大药店
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                    数据运营
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                   15-20元/时
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">1年以内</span>
                                <span className="badge badge-secondary mr-1">本科</span>
                                <span className="badge badge-secondary mr-1">用户深度研究</span>
                                <span className="badge badge-secondary mr-1">业务数据分析</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                    知往科技
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                    android高级开发工程师
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    30-60K·16薪
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">5-10年</span>
                                <span className="badge badge-secondary mr-1">本科</span>
                                <span className="badge badge-secondary mr-1">安卓客户端</span>
                                <span className="badge badge-secondary mr-1">Java</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                   百度
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                    上市贝壳（链家）诚聘置业顾问
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    10-15K
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">经验不限</span>
                                <span className="badge badge-secondary mr-1">本科</span>
                                <span className="badge badge-secondary mr-1">房屋买卖和租赁</span>
                                <span className="badge badge-secondary mr-1">实地看房</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                   链家
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                    硬件研发总监/CTO/人形机器人研发
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    30-50K
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">经验不限</span>
                                <span className="badge badge-secondary mr-1">学历不限</span>
                                <span className="badge badge-secondary mr-1">嵌入式技术</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                    艾拉精灵
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                    gis开发工程师
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    15-28K
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">1-3年</span>
                                <span className="badge badge-secondary mr-1">本科</span>
                                <span className="badge badge-secondary mr-1">C/C++</span>
                                <span className="badge badge-secondary mr-1">SuperMap</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                    帝测科技
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-2 ">
                        <div className={'news-item'} style={{border: "1px solid #00bebd", borderRadius: 10, padding: 10}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#00a6a7', fontSize: 16, fontWeight: '500'}}>
                                    美团招聘理货员
                                </div>
                                <div style={{color: '#fe574a', fontSize: 20, fontWeight: '500'}}>
                                    8-9K
                                </div>
                            </div>
                            <div className="pb-3">
                                <span className="badge badge-secondary mr-1">经验不限</span>
                                <span className="badge badge-secondary mr-1">学历不限</span>
                                <span className="badge badge-secondary mr-1">全职</span>
                                <span className="badge badge-secondary mr-1">兼职</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{color: '#666', fontSize: 13}}>
                                    美团
                                </div>
                                <div style={{color: '#666', fontSize: 13}}>
                                    北京
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>)
}
export default index;
